<template>
  <b-button @click="goBack">
    <i class="fas fa-backward"></i> {{ $t("links.back") }}
  </b-button>
</template>

<script>
export default {
  name: "BackButton",
  props: ["size"],
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style></style>
