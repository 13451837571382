<template>
  <section>
    <b-container>
      <div v-if="getLoadingFromAuthentication" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <b-card v-else class="" header="Profile">
        <b-form>
          <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
          <b-form-input
            readonly
            type="text"
            hidden
            v-model="user.id"
          ></b-form-input>

          <b-form-group id="input-username" label="User name">
            <b-form-input
              type="text"
              readonly
              v-model="user.username"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-firstname" label="First name">
            <b-form-input type="text" v-model="user.firstName"></b-form-input>
          </b-form-group>

          <b-form-group id="input-lastname" label="Last name">
            <b-form-input type="text" v-model="user.lastName"></b-form-input>
          </b-form-group>

          <b-form-group id="input-company" label="Company">
            <b-form-input type="text" v-model="user.company"></b-form-input>
          </b-form-group>

          <b-form-group id="input-mail" label="Mail">
            <b-form-input type="text" v-model="user.email"></b-form-input>
          </b-form-group>

          <b-form-group id="input-phone" label="Phone">
            <b-form-input type="text" v-model="user.phone"></b-form-input>
          </b-form-group>

          <b-form-group id="input-changepass">
            <b-button variant="primary" @click="changePassword"
              >Change password</b-button
            >
          </b-form-group>

          <b-button
            type="submit"
            @click="onSubmit"
            variant="success"
            class="mr-2"
            >Save</b-button
          >
          <back-button></back-button>
        </b-form>
      </b-card>
    </b-container>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BackButton from "@/components/Buttons/BackButton";

export default {
  name: "profile",
  components: {
    BackButton
  },
  data() {
    return {
      user: null,
      error: null
    };
  },
  computed: {
    ...mapGetters(["getUser", "getLoadingFromAuthentication"])
  },
  created() {
    this.user = this.getUser || null;
  },
  methods: {
    ...mapActions(["updateUser"]),
    onSubmit(e) {
      e.preventDefault();
      this.updateUser(this.user)
        .then(() => {
          this.user = this.getUser;
        })
        .catch(err => {
          this.error = err;
        });
    },
    changePassword() {
      this.$router.push("/profile/changepassword");
    }
  }
};
</script>
